import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import styled from "styled-components"
import profilePic from '../images/kim-lindgren.jpg'

const About = () => (
  <Layout>
    <Seo title="About" />
    <h1>About me</h1>
    <BorderedGrid>
      <section >
        <div className="pb-4 col-span-2 row-span-1">
          <p>My name is Kim Lindgren and I am a certified evolutionary astrologer based in Sweden.</p>
          <p>Pluto Astrology is my website, blog and personal home in cyberspace. The name is based on the type of astrology I practice: Evolutionary Astrology (EA) based on the Pluto paradigm, thus Pluto astrology.</p>
        </div>
        <div className="responsive-bg-image col-span-2 row-span-2 mb-8" style={{
          backgroundImage: `url(${profilePic})`,
          height: `450px`
        }}>

        </div>
        <section className="grid-item">
          <h3>Background</h3>
          <p>After many years in academia, I started studying astrology in depth during the summer of 2018.</p>
          <p>I studied for <a href="https://www.easchoolonline.com/">Michael de Baker</a> to  become a certified evolutionary astrologer in December 2021. During and after my education, I have so far held around 100 client consultations at 90 minutes or longer.</p>
        </section>
        <section className="grid-item">
          <h3>Counseling</h3>
          <p>I offer <Link to="/counseling">astrological consultations</Link> for anyone regardless of astrological interest. Whether you want help to interpret your birth chart or just want to gain perspective on your life without unnecessary astrological jargon - we will adapt the consultation to suit your needs.</p>
        </section>
        <section className="grid-item ">
          <h3>Social Media</h3>
          <p>The <Link to="/blog">blog page</Link> is where I share my own thought and ideas regarding EA: methodology, chart presentations, musings on the archetypes, mundane astrology and more.</p>
          <p>I have an <a href="https://www.facebook.com/plutoastrologen">astrology Facebook page</a> primarily directed at Swedish clients. You can also follow me on <a href="https://instagram.com/theplutoastrologer">Instagram</a>, where I will notify about new blog posts.</p>
        </section>
        <section className="grid-item">
          <h3>Skylogs</h3>
          <p>I am the founder of the collaborative astrology platform Skylogs that will be launched some time in 2025.</p>
          {/* <p>I prefer tea over coffee and cats over dogs.</p>
          <p>I have six planets retrograde, two T-squares and four close conjunctions.</p> */}
        </section>
      </section>
    </BorderedGrid>
  </Layout>
)

const BorderedGrid = styled.div`
  margin-top: 0rem;
  .grid {
    grid-gap: 1px;
  }
  .grid-item {
    background-color: white;
  }
`

export default About
